<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title" />
      <div class="flex-fill p-3">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import StickyHeader from '../components/StickyHeader.vue'
export default {
  components: { StickyHeader },
}
</script>
