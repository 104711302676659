<template>
  <header class="border-bottom bg-light d-flex justify-content-between p-4 position-sticky sticky-top w-100">
    <div class="d-flex flex-fill flex-row title">
      <b-button @click="toggle" class="align-self-center d-md-none mr-2" variant="primary"><fa-icon icon="bars" /></b-button>
      <div class="align-self-center d-flex flex-column flex-md-row align-items-left">
        <slot name="title"><h1 :class="{ 'has-subtitle': subtitle }" class="align-self-left align-self-md-center font-weight-bold my-0 title text-truncate">{{ title }}</h1></slot>
        <slot name="subtitle"><p class="align-self-left align-self-md-center font-weight-light my-0 subtitle text-truncate ml-0 ml-md-2">{{ subtitle }}</p></slot>
      </div>
    </div>
    <div class="buttons">
      <slot name="buttons" />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
  methods: {
    toggle() {
      this.$root.$emit('dd::sidebar::toggle');
    }
  }
}
</script>

<style scoped>
header {
  min-height: 85px;
  flex-direction: row;
}

.buttons {
  display: flex;
  margin-top: -1px;
  margin-bottom: -1px;
  text-align: right;
}

.buttons a,
.buttons button,
.buttons div.dropdown {
  text-align: initial;
  align-self: center;
}

.title, .subtitle {
  min-width: 0;
}

.title {
  font-size: 16pt;
}

.title.has-subtitle {
  font-size: 16pt;
}

.subtitle {
  font-size: 16pt;
}

@media (max-width: 1200px) {
  header {
    min-height: 85px;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 18pt;
  }
  .title.has-subtitle {
    font-size: 12pt;
  }

  .subtitle {
    font-size: 10pt;
  }
}
</style>
